var debounce = require("./debounce");

/*-----------------------------------------------------------------*//**
* Fix #navbar on top of the page on scroll event
*
* @author          Viktor Starynskyi
* @project         CACO1607 - CB
* @added           14.10.2017
* @contributors    Viktor Starynskyi
*/

module.exports = function fixNavBarOnScroll() {
  (function($) {

    var Vars = {};
        Vars.page = $('#page');
        Vars.navbar = Vars.page.children('#navbar');
        Vars.height = 0;
        Vars.fixOffset = 0;
        Vars.getVars = function() {
            Vars.height = Vars.navbar.outerHeight();
            Vars.fixOffset = Vars.height * 1.4;
        };
        Vars.currentHeight = function() {
            return Vars.navbar.outerHeight();
        };

    Vars.getVars();

    var handleScroll = function() {
        if ( $(window).scrollTop() > Vars.fixOffset ) {
            if ( !Vars.navbar.hasClass('fixed') ) {
                Vars.page.css('padding-top', Vars.height);
                Vars.navbar.addClass('fixed').css('top', '-'+Vars.currentHeight()+'px');
            }
        } else {
            Vars.navbar.removeClass('fixed').removeAttr('style');
            Vars.page.removeAttr('style');
        }
    };

    var handleResize = debounce(function() {
        if ( Vars.navbar.hasClass('fixed') ) {
            Vars.getVars();
            Vars.navbar.css('top', '-'+Vars.currentHeight()+'px');
        }
    }, 100);

    $(window).on('scroll', handleScroll);
    $(window).on('resize orientationchange', handleResize);

  })(jQuery);
}
